import React from 'react'
import Layout from '../../components/layout'
import Clap from '../../icons/clap-2.svg'
import BlogFeed from '../../components/blog-feed'

const MigrateToZendeskTY = () => {
    return (
        <Layout>
            <section className="pt-6">
                <div className="container">
                    <div className="row">
                        <div className="col-12 text-center">
                            <Clap
                                className="text-primary mb-6"
                                width={149}
                            />
                            <h1>Thank You!</h1>
                            <h3>We're excited to speak with you, and will reach out soon.</h3>
                        </div>
                    </div>
                </div>
            </section>

            <section className="pt-6">
                <div className="container">
                    <div className="row">
                        <div className="col-12 text-center">
                            <BlogFeed title="While You Wait, Check Out Some Of Our Zendesk Articles" />
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    )
}

export default MigrateToZendeskTY
